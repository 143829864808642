.divMenuBgg {
  background-image: url(http://localhost:3000/images/bg-01.jpg);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.05;
  background-size: cover;
}
li.nav-item.active {
  background-color: black;
  border-bottom-color: blue;
}